import logo from "../img/logooriginal.svg";
import React from 'react';
import { withTranslation } from 'react-i18next';
import './NavBar.css'
import { NavLink, Redirect } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';
import Language from "./Language";

const NavBar = (props) => {
    const { t } = props;

    const scrollMe = (e, data) => {
        e.preventDefault();
        let t = document.getElementById(data);
        if (t) {

            window.scrollTo(0, t.offsetTop);
        }
        else {
            window.location = "/home";
            window.scrollTo(0, t.offsetTop);
        }
    };

    return (

        <Navbar style={{ justifyContent: "space-between", margin: "auto", width: "95%" }} expand="lg">
            <Navbar.Brand href="#home"><img onClick={scrollMe} className="navbar-img" src={logo} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav style={{ marginLeft: "auto" }}>
                    <div className="navbar-other-buttons">
                        <Nav.Link href="/home"><a style={{ color: "#434444", fontWeight: "bold" }}>{t("NAVBAR-HOME")}</a></Nav.Link>
                    </div>
                    <div className="navbar-other-buttons">
                        <Nav.Link href="/services" onClick={e => scrollMe(e, "services")}><a style={{ color: "#434444", fontWeight: "bold" }}>{t("NAVBAR-SERVICES")}</a></Nav.Link>
                    </div>
                    {/*<Nav.Link href="/news">{t("NAVBAR-NEWS")}</Nav.Link>*/}
                    <div className="navbar-other-buttons">
                        <Nav.Link href="/apps"><a style={{ color: "#434444", fontWeight: "bold" }}>{t("NAVBAR-APPS")}</a></Nav.Link>
                    </div>
                    <div className="navbar-other-buttons">
                        <Nav.Link href="//blog.agroinsider.com"><a style={{ color: "#434444", fontWeight: "bold" }}>{t("NAVBAR-BLOG")}</a></Nav.Link>
                    </div>
                    <div className="navbar-other-buttons">
                        <Nav.Link href="/clientarea"><a style={{ color: "#434444", fontWeight: "bold" }}>{t("CLIENT-AREA-TITLE")}</a></Nav.Link>
                    </div>
                    <div className="navbar-other-buttons">
                        <Nav.Link href="#contactus" onClick={e => scrollMe(e, "contactus")}><a style={{ color: "#434444", fontWeight: "bold" }}>{t("NAVBAR-CONTACTUS")}</a></Nav.Link>
                    </div>
                    <div className="navbar-other-buttons">
                        <Language />
                    </div>

                </Nav>
            </Navbar.Collapse>
        </Navbar>


    )
}


export default withTranslation()(NavBar);
