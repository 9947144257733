import { useState } from "react";
import React from 'react';
import { withTranslation } from 'react-i18next';
import './Contactform.css'
import i18n from '../i18n';

const Contactform = (props) => {
    const { t, lang } = props;

    return (
        <>
            {
                i18n.language === "en-GB" && (
                    <div id="contactus">
                        <div className="contactform-title">
                            <span>{t("CONTACTUS-TITLE")}</span>
                        </div>
                        <div className="contactform-subtitle">
                            <span>{t("CONTACTUS-SUBTITLE")}</span>
                        </div>
                        <div className="contactform-card-container" data-pd-webforms={'https://webforms.pipedrive.com/f/1ys1kjP3zPxcXEvC3pSJNF406aiX3ftRTKTa0BYypVLrnhoEftER5uFbzyFEIUBUf'}>
                            <iframe
                                src={`https://webforms.pipedrive.com/f/1ys1kjP3zPxcXEvC3pSJNF406aiX3ftRTKTa0BYypVLrnhoEftER5uFbzyFEIUBUf`}

                                scrolling="no"
                                seamless="seamless"
                                style={{
                                    border: 'none',
                                    overflow: 'hidden',
                                    width: '100%',
                                    maxWidth: '768px',
                                    minWidth: '320px',
                                    height: '50em',
                                    position: 'relative',
                                }}
                            />

                        </div>
                    </div>
                )
            }
            {
                i18n.language === "pt-PT" && (
                    <div id="contactus">
                        <div className="contactform-title">
                            <span>{t("CONTACTUS-TITLE")}</span>
                        </div>
                        <div className="contactform-subtitle">
                            <span>{t("CONTACTUS-SUBTITLE")}</span>
                        </div>
                        <div className="contactform-card-container" data-pd-webforms={'https://webforms.pipedrive.com/f/339Dp6S28oVYZhk610Makcz00cuyBdYCqmLA5e1UHlaevQy817TR4rhwXZWFjXdoT'}>
                            <iframe
                                src={`https://webforms.pipedrive.com/f/339Dp6S28oVYZhk610Makcz00cuyBdYCqmLA5e1UHlaevQy817TR4rhwXZWFjXdoT`}

                                scrolling="no"
                                seamless="seamless"
                                style={{
                                    border: 'none',
                                    overflow: 'hidden',
                                    width: '100%',
                                    maxWidth: '768px',
                                    minWidth: '320px',
                                    height: '50em',
                                    position: 'relative',
                                }}
                            />

                        </div>
                    </div>
                )

            }

            {   
                i18n.language === "es-ES" && (
                    <>
                    <div id="contactus">
                        <div className="contactform-title">
                            <span>{t("CONTACTUS-TITLE")}</span>
                        </div>
                        <div className="contactform-subtitle">
                            <span>{t("CONTACTUS-SUBTITLE")}</span>
                        </div>
                        <div className="contactform-card-container" data-pd-webforms={'https://webforms.pipedrive.com/f/33bkXvrrYeXO7peWbHAcQ1GwaGUz9s9acdiySscbJHR9JA4qZ5aLAMVilVNb9p9Wr'}>
                            <iframe
                                src={`https://webforms.pipedrive.com/f/33bkXvrrYeXO7peWbHAcQ1GwaGUz9s9acdiySscbJHR9JA4qZ5aLAMVilVNb9p9Wr`}

                                scrolling="no"
                                seamless="seamless"
                                style={{
                                    border: 'none',
                                    overflow: 'hidden',
                                    width: '100%',
                                    maxWidth: '768px',
                                    minWidth: '320px',
                                    height: '50em',
                                    position: 'relative',
                                }}
                            />

                        </div>
                    </div>
                    
                    </>
                )

            }

        </>
    );
}

export default withTranslation()(Contactform);
