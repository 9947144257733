import React from 'react';
import { withTranslation } from 'react-i18next';
import './Newsletter.css'

const Newsletter = (props) => {
    const { t } = props;

    return (
        <>
        <div className="newsletter-container">
            <div className="newsletter-text-container">
                <div className="newsletter-title">
                    <span>{t("NEWSLETTER")}</span>
                </div>
                <form action="https://agroinsider.us1.list-manage.com/subscribe/post?u=b0c258333a135c14e2e86d55c&amp;id=a766cc4c1f" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                    <div className="newsletter-form">
                            <input type="email" name="EMAIL" class="email" id="mce-EMAIL" required placeholder={t("ENTER-EMAIL")} className="subscribe-input"></input>
                            <button type="submit" value="Subscribe" name="subscribe" className="subscribe-button">{t("NEWSLETTER-SUBSCRIBE")}</button>
                    </div>
                </form>
            </div>
        </div>  
        </>
    )
}


export default withTranslation()(Newsletter);
