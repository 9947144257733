import yourinformations from "../img/yourinformations.svg";
import informationtoprepare from "../img/informationtoprepare.svg";
import informationtohelp from "../img/informationtohelp.svg";
import harvestoptimization from "../img/harvestoptimization.svg";
import React from 'react';
import { withTranslation } from 'react-i18next';

const WhatWeOffer = (props) => {
  const { t } = props;

    return(
      <div id = { props.id } className = "uk-container" >
        <div className="uk-width-xlarge uk-margin-auto uk-text-center uk-margin-large-bottom">
          <h1 className="uk-h6 tm-h6 uk-margin-xlarge-top">
            <p id="WhatWeOffer.alt">{t("WHATWEOFFER-ALT")}</p>
          </h1>
          <h2 className="uk-h1 uk-margin-top">
            <p id="WhatWeOffer.title">{t("WHATWEOFFER-TITLE")}</p>
          </h2>
          <p className="uk-text-large tm-text-large">
            <p id="WhatWeOffer.sub">
                    {t("WHATWEOFFER-SUB")}
            </p>
          </p>
        </div>
        <div
          uk-grid
          className="uk-child-width-4-4@m uk-child-width-1-2@s uk-grid uk-grid-match"
          uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 500"
        >
          <div className="uk-grid-margin uk-text-center">
            <div className="uk-card uk-card-default">
              <div className="uk-text-center" style={{ paddingTop: "40px" }}>
                <img
                  uk-svg
                  width="125"
                  height="125"
                  //src="https://image.flaticon.com/icons/svg/498/498260.svg"
                  src={yourinformations}
                  alt="Sonic Style"
                />
              </div>
              <div className="uk-card-body">
                <h3 className="uk-card-title">
                  <span className="uk-text-success">
                    <p id="WhatWeOffer.b0.l1">{t("WHATWEOFFER-B0-L1")}</p>
                  </span>
                  <p id="WhatWeOffer.b0.l2">{t("WHATWEOFFER-B0-L2")}</p>
                </h3>
                <div>
                  <p id="WhatWeOffer.b0.text1">{t("WHATWEOFFER-B0-TEXT1")}</p>
                </div>
                <div>
                  <p id="WhatWeOffer.b0.text2">
                  {t("WHATWEOFFER-B0-TEXT2")}
                  </p>
                </div>
                <div>
                  <p id="WhatWeOffer.b0.text3">{t("WHATWEOFFER-B0-TEXT3")}</p>
                </div>
                <div>
                  <p id="WhatWeOffer.b0.text4">
                                {t("WHATWEOFFER-B0-TEXT4")}
                  </p>
                </div>
                <div>
                  <p id="WhatWeOffer.b0.text5">
                    {t("WHATWEOFFER-B0-TEXT5")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="uk-grid-margin uk-text-center">
            <div className="uk-card uk-card-default">
              <div className="uk-text-center" style={{ paddingTop: "40px" }}>
                <img
                  uk-svg
                  width="125"
                  height="125"
                  //src="https://image.flaticon.com/icons/svg/498/498252.svg"
                  src={informationtoprepare}
                  alt="Sonic Style"
                />
              </div>
              <div className="uk-card-body">
                <h3 className="uk-card-title">
                  <span className="uk-text-success">
                    <p id="WhatWeOffer.b1.l1">{t("WHATWEOFFER-B1-L1")}</p>
                  </span>
                  <p id="WhatWeOffer.b1.l2">{t("WHATWEOFFER-B1-L2")}</p>
                </h3>
                <div>
                  <p id="WhatWeOffer.b1.text1">{t("WHATWEOFFER-B1-TEXT1")}</p>
                </div>
                <div>
                  <p id="WhatWeOffer.b1.text2">{t("WHATWEOFFER-B1-TEXT2")}</p>
                </div>
                <div>
                  <p id="WhatWeOffer.b1.text3">
                    {t("WHATWEOFFER-B1-TEXT3")}
                  </p>
                </div>
                <div>
                  <p id="WhatWeOffer.b1.text4">
                    {t("WHATWEOFFER-B1-TEXT4")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="uk-grid-margin uk-text-center">
            <div className="uk-card uk-card-default">
              <div className="uk-text-center" style={{ paddingTop: "40px" }}>
                <img
                  uk-svg
                  width="125"
                  height="125"
                  //src="https://image.flaticon.com/icons/svg/498/498234.svg"
                  src={informationtohelp}
                  alt="Sonic Style"
                />
              </div>
              <div className="uk-card-body">
                <h3 className="uk-card-title ">
                  <span className="uk-text-success">
                    <p id="WhatWeOffer.b2.l1">{t("WHATWEOFFER-B2-L1")}</p>
                  </span>
                  <p id="WhatWeOffer.b2.l2">{t("WHATWEOFFER-B2-L2")}</p>
                </h3>
                <div>
                  <p id="WhatWeOffer.b2.text1">
                    {t("WHATWEOFFER-B2-TEXT1")}
                  </p>
                </div>
                <div>
                  <p id="WhatWeOffer.b2.text2">
                  {t("WHATWEOFFER-B2-TEXT2")}
                  </p>
                </div>
                <div>
                  <p id="WhatWeOffer.b2.text3">
                  {t("WHATWEOFFER-B2-TEXT3")}
                  </p>
                </div>
                <div>
                  <p id="WhatWeOffer.b2.text4">
                  {t("WHATWEOFFER-B2-TEXT4")}
                  </p>
                </div>
                <div>
                  <p id="WhatWeOffer.b2.text5">
                  {t("WHATWEOFFER-B2-TEXT5")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="uk-grid-margin uk-text-center">
            <div className="uk-card uk-card-default">
              <div className="uk-text-center" style={{ paddingTop: "40px" }}>
                <img
                  uk-svg
                  width="125"
                  height="125"
                  //src="https://image.flaticon.com/icons/svg/498/498263.svg"
                  src={harvestoptimization}
                  alt="Sonic Style"
                />
              </div>
              <div className="uk-card-body">
                <h3 className="uk-card-title">
                  <span className="uk-text-success">
                    <p id="WhatWeOffer.b3.l1">{t("WHATWEOFFER-B3-L1")}</p>
                  </span>
                  <p id="WhatWeOffer.b3.l2">{t("WHATWEOFFER-B3-L2")}</p>
                </h3>
                <div>
                  <p id="WhatWeOffer.b3.text1">
                  {t("WHATWEOFFER-B3-TEXT1")}
                  </p>
                </div>
                <div>
                  <p id="WhatWeOffer.b3.text2">
                  {t("WHATWEOFFER-B3-TEXT2")}
                  </p>
                </div>
                <div>
                  <p id="WhatWeOffer.b3.text3">
                  {t("WHATWEOFFER-B3-TEXT3")}
                  </p>
                </div>
                <div>
                  <p id="WhatWeOffer.b3.text4">{t("WHATWEOFFER-B3-TEXT4")}</p>
                </div>
                <div>
                  <p id="WhatWeOffer.b3.text5">{t("WHATWEOFFER-B3-TEXT5")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="uk-margin-remove-bottom uk-margin-xlarge-top" />
      </div>
    );
}

export default withTranslation()(WhatWeOffer);
