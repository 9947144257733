import React from 'react';
import { withTranslation } from 'react-i18next';

const FullVidBlock = (props) => {
  const { t } = props;

    return (
      <div
        style={{
          maxHeight: "100vh",
          height: "100%",
          maxWidth: "100vw",
          width: "100%",
          position: "relative"
        }}
      >
        <video
          style={{ objectFit: "fill", height: "100%", width: "100%" }}
          src="https://agroinsider.com/img/vid.webm"
          loop
          muted
          playsinline
          uk-video="autoplay: inview"
        />
      </div>
    );
}

export default withTranslation()(FullVidBlock);
