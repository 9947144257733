import antonio from "../img/fotos/antonio.png";
import Carlos from "../img/fotos/carlos.png";
import Filipa from "../img/fotos/filipa.png";
import Filipe from "../img/fotos/filipe.png";
import Francisco from "../img/fotos/francisco.png";
import Marco from "../img/fotos/marco.png";
import Marcos from "../img/fotos/marcos.png";
import Mariano from "../img/fotos/mariano.png";
import Mario from "../img/fotos/mario.png";
import Rafael from "../img/fotos/rafael.png";
import Caroline from "../img/fotos/caroline.png";
import React from 'react';
import { withTranslation } from 'react-i18next';

const Team = (props) => {
    const { t } = props;
    const personCard = (name, img, jobdesc, bio) => {
        return (
            <div className="uk-grid-margin uk-text-center uk-preserve-color">
                <div className="uk-card uk-card-default uk-card-small">
                    <div className="uk-text-center" style={{ paddingTop: "40px" }}>
                        <div
                            style={{
                                backgroundImage: `url('${img}')`
                            }}
                            className="avatar"
                        />
                    </div>
                    <div className="uk-card-body">
                        <div className="uk-width-expand">
                            <h3 className="uk-card-title uk-margin-remove-bottom">{name}</h3>
                            <p className="uk-text-meta uk-margin-remove-top">
                                <span>{jobdesc}</span>
                            </p>
                        </div>
                        <div>{bio}</div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div
            id={props.id}
            className="uk-section uk-section-primary uk-preserve-color uk-section-large"
        >
            <div className="uk-container">
                <div className="uk-width-xlarge uk-margin-auto uk-text-center uk-margin-large-bottom uk-section-primary">
                    <h1 className="uk-h6 tm-h6">
                        <p id="Team.title">{t("TEAM-TITLE")}</p>
                    </h1>
                    <h2 className="uk-h1 uk-margin-top">
                        <p id="Team.sub">{t("TEAM-SUB")}</p>
                    </h2>
                    <p className="uk-text-large tm-text-large">
                        <p id="Team.desc">
                            {t("TEAM-DESC")}
              </p>
                    </p>
                </div>
                <div
                    uk-grid
                    uk-scrollspy="cls: uk-animation-fade; repeat: true"
                    className="uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid uk-flex-center uk-grid-match"
                >
                    {personCard("José Rafael Silva", Rafael, "Founder/Science")}
                    {personCard("Mário Luís", Mario, "Founder/Business")}
                    {personCard("Filipe Vieira", Filipe, "TIC Developer")}
                    {personCard("Marco Sacristão", Marco, "TIC Developer")}
                    {personCard("Carlos Palma", Carlos, "TIC Developer")}
                    {personCard("Filipa Santos", Filipa, "Design & Communication")}
                    {personCard("Francisco Marques", Francisco, "Agronomist")}
                    {personCard("Marcos Cortes", Marcos, "Sales manager CM")}
                    {personCard("Mariano Terrón", Mariano, "TIC Developer")}
                    {personCard("Caroline Reimann", Caroline, "Marketing")}
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(Team);
