import { useState } from "react";
import React from 'react';
import { withTranslation } from 'react-i18next';

const ContactForm = (props) => {
    const [success, setsuccess] = useState(null);
    const [error, setError] = useState(null);
    const { t, lang } = props;
    var inputNode;

    const handleSubmit = e => {
        e.preventDefault();
        let request = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: e.target.name.value,
                email: e.target.email.value,
                phone: e.target.phone.value,
                subject: e.target.subject.value,
                message: e.target.message.value,
                languageCode: lang
            })
        };
        return fetch(process.env.REACT_APP_API_URL + "/0/usermessage", request)
            .then(r =>
                setsuccess(t("CONTACT-SUCESS"))
                    .catch(e =>
                        setError(t("CONTACT-ERROR"))
                    ));
    };

    const renderMessage = () => {
        if (success) {
            return (
                <div className="uk-alert-success" uk-alert>
                    <a className="uk-alert-close" uk-close />
                    <p>
                        <p id="Contact.success">{success}</p>
                    </p>
                </div>
            );
        } else if (!success && error) {
            return (
                <div className="uk-alert-danger" uk-alert>
                    <a className="uk-alert-close" uk-close />
                    <p>
                        <p id="Contact.error">{error}</p>
                    </p>
                </div>
            );
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <fieldset className="uk-fieldset">
                <legend className="uk-legend">
                    <p id="Contact.title">{t("CONTACT-TITLE")}</p>
                </legend>

                <div className="uk-margin">
                    <div className="uk-inline">
                        <i className="uk-form-icon" href="#" uk-icon="icon: pencil" />

                        <input
                            id="name"
                            className="uk-input"
                            type="text"
                            placeholder={t("CONTACT-NAME")}
                            ref={node => (inputNode = node)}
                        />

                    </div>
                </div>

                <div className="uk-margin">
                    <div className="uk-inline">
                        <i className="uk-form-icon" href="#" uk-icon="icon: mail" />

                        <input
                            id="email"
                            className="uk-input"
                            type="text"
                            placeholder={t("CONTACT-EMAIL")}
                            required
                            ref={node => (inputNode = node)}
                        />

                    </div>
                </div>

                <div className="uk-margin">
                    <div className="uk-inline">
                        <i className="uk-form-icon" href="#" uk-icon="icon: phone" />

                        <input
                            id="phone"
                            className="uk-input"
                            type="text"
                            placeholder={
                                t("CONTACT-PHONE")
                            }
                            ref={node => (inputNode = node)}
                        />

                    </div>
                </div>

                <div className="uk-margin">
                    <div className="uk-inline">
                        <i className="uk-form-icon" href="#" uk-icon="icon: pencil" />

                        <input
                            id="subject"
                            className="uk-input"
                            type="text"
                            placeholder={t("CONTACT-SUBJECT")}
                            ref={node => (inputNode = node)}
                        />

                    </div>
                </div>

                <div className="uk-margin">
                    <div className="uk-inline">
                        <i className="uk-form-icon" href="#" uk-icon="icon: file-edit" />

                        <textarea
                            id="message"
                            className="uk-textarea"
                            rows="5"
                            placeholder={t("CONTACT-MESSAGE")}
                            required
                            ref={node => (inputNode = node)}
                        />

                    </div>
                </div>
            </fieldset>
            <button
                className="uk-button uk-button-primary uk-width-1-1 uk-margin-small-bottom"
                disabled={success ? true : false}
            >
                <span id="Contact.send">{t("CONTACT-SEND")}</span>
            </button>
            {renderMessage}
        </form>
    );
}

const Contact = (props) => {
    const { t, lang } = props;
    return (
        <div id={props.id}>
            <div className="uk-container">
                <hr />
            </div>
            <div className="uk-section uk-section-default uk-section-large">
                <div className="uk-container">
                    <div className="uk-width-xlarge uk-margin-auto uk-text-center uk-margin-large-bottom">
                        <h1 className="uk-h6 tm-h6 uk-margin-remove">
                            <p id="Contact.reachOut">{t("CONTACT-REACHOUT")}</p>
                        </h1>
                        <h2 className="uk-h1 uk-margin-top">
                            <p id="Contact.talkToUs">{t("CONTACT-TALKTOUS")}</p>
                        </h2>
                        <p className="uk-text-large tm-text-large">
                            <p id="Contact.questions">
                                {t("CONTACT-QUESTIONS")}
                </p>
                        </p>
                    </div>
                    <div />
                    <ContactForm lang={lang} t = {t} />
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(Contact);
