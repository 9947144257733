import React from 'react';
import { withTranslation } from 'react-i18next';
import About from '../components/About';
import Apps from '../components/Apps';
import Contactform from '../components/Contactform';
import Footer2 from '../components/Footer2';
import MainServices from '../components/MainServices';
import Marketing from '../components/Marketing';
import NavBar from '../components/NavBar';
import Newsletter from '../components/Newsletter';
import Services from '../components/Services';
import './HomePage.css'

const AppPage = (props) => {
    const { t } = props;

    return (
        <>
        <NavBar t = {props.t} />
        <Marketing t = {props.t} />
        <Newsletter t = {props.t}/>
        <Contactform t = {props.t} />
        <Footer2 t = {props.t} />
        </>
    )
}


export default withTranslation()(AppPage);
