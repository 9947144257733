import React from 'react';
import { withTranslation } from 'react-i18next';
import './About.css'
import sustentabilidade from '../img/sustentabilidade.png';
import carbono from '../img/mercadocarbono.png';
import inovacao from '../img/inovacao.png'
import { NavLink } from 'react-router-dom';

const About = (props) => {
    const { t } = props;

    const scrollMe = (e, data) => {
        e.preventDefault();
        let t = document.getElementById(data);
        window.scrollTo(0, t.offsetTop);
    };
    
    return (
        <div id="about" className="about-container">
            <div style={{marginTop:"5em"}} className="about-text-image-container">
                <div className="about-text-container">
                    <div className="title">
                        <span>
                            {t("ABOUTUS-ENVIRONMENTAL-TITLE")}
                        </span>
                    </div>
                    <div className="text">
                        <span>{t("ABOUTUS-ENVIRONMENTAL-TEXT")}</span>
                    </div>
                </div>
                <div>
                    <img src={sustentabilidade} />
                </div>
            </div>

            <div style={{backgroundColor:"#e5e4e2"}}>
                <div style={{paddingTop:"3em", paddingBottom:"3em"}} className="about-text-image-container text-image-column">
                        <div>
                            <img className="margin-left-img-carbon" src={carbono} />
                        </div>
                        <div className="about-text-container">
                            <div className="title">
                                <span>
                                {t("ABOUTUS-CARBON-TITLE")}
                                </span>
                            </div>
                            <div className="text">
                                <span>{t("ABOUTUS-CARBON-TEXT")}</span>
                                
                            </div>
                            
                            <NavLink to = '/about' activeClassName="active-know-more" className="inactive-know-more-button">{t("ABOUTUS-KNOW-MORE")}</NavLink>
                                
                            
                        </div>
                    
                </div>
            </div>

            <div className="about-text-image-container">
                <div className="about-text-container">
                    <div className="title">
                        <span>
                            {t("ABOUTUS-INNOVATION-TITLE")}
                        </span>
                    </div>
                    <div className="text">
                        <span>{t("ABOUTUS-INNOVATION-TEXT")}</span>
                    </div>

                        <NavLink to = '/apps' activeClassName="active-know-more" className="inactive-know-more-button">{t("ABOUTUS-DISCOVER-APPS")}</NavLink>
                    
                </div>
                <div>
                    <img src={inovacao} />
                </div>
            </div>

        </div>
    )   
}


export default withTranslation()(About);