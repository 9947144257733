import React from 'react';
import { withTranslation } from 'react-i18next';
import './CountrySection.css';

const CountrySection = (props) => {
    const {t} = props;
    return (
        <div className="CountrysContainer">
            <p>Portugal</p>
            <p>Brasil</p>
            <p>America Central</p>
        </div>
    )
}



export default withTranslation()(CountrySection);