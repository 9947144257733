import React from 'react';
import { withTranslation } from 'react-i18next';
import './Clipping.css'


const Clipping = (props) => {
    return (
        <div id="clipping" className="ClippingContainer" uk-scrollspy="cls: uk-animation-fade; repeat: true" >
            <h1>Marketing</h1>
        </div>
        
    )
}

export default withTranslation()(Clipping);