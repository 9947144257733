import React from 'react';
import { withTranslation } from 'react-i18next';

const CallToAction = (props) => {

  const { t, id } = props;

  const scrollMe = (e, data) => {
    e.preventDefault();
    let t = document.getElementById(data);
    window.scrollTo(0, t.offsetTop);
  };

  return (
    <div
      id={id}
      className="uk-section uk-section-primary tm-section-primary uk-section-xlarge uk-padding-remove-bottom imagify"
    >
      <div className="uk-container uk-text-center">
        <h2 className="uk-h1">
          <p id="CallToAction.title">{t("CALLTOACTION-TITLE")}</p>
        </h2>
        <p className="uk-text-large tm-text-large uk-margin-medium-bottom">
          <p id="CallToAction.subtitle">
            {t("CALLTOACTION-SUBTITLE")}
            </p>
        </p>
        <h3 className="uk-h3">
          <p id="CallToAction.price">{t("CALLTOACTION-PRICE")}</p>
        </h3>
        <h4 className="uk-h6">
          <p id="CallToAction.software"> 
            {t("CALLTOACTION-SOFTWARE")}
            </p>
        </h4>
        <div
          uk-grid=""
          className="uk-grid-medium uk-flex-middle uk-flex-center uk-grid"
        >
          <div className="uk-first-column">
            <a
              href="#contactus"
              onClick={e => scrollMe(e, "contactus")}
              className="uk-button uk-button-default uk-button-large tm-button-primary tm-button-large uk-visible@s"
            >
              <span id="CallToAction.talkToUs">{t("CALLTOACTION-TALKTOUS")}</span>
            </a>
          </div>
          <div>
            <a
              href="https://agromap.agroinsider.com"
              className="uk-button uk-button-text"
            >
              <span id="CallToAction.visitAgromap">{t("CALLTOACTION-VISITAGROMAP")}</span>
            </a>
          </div>
        </div>
        <hr className="uk-margin-remove-bottom uk-margin-xlarge-top" />
      </div>
    </div>
  );
}

export default withTranslation()(CallToAction);
