import React from 'react';
import { withTranslation } from 'react-i18next';
import About from '../components/About';
import About2 from '../components/About2';
import ClientArea from '../components/ClientArea';
import Contactform from '../components/Contactform';
import Footer2 from '../components/Footer2';
import MainServices from '../components/MainServices';
import Marketing from '../components/Marketing';
import NavBar from '../components/NavBar';
import Newsletter from '../components/Newsletter';
import Services from '../components/Services';

const ClientAreaPage = (props) => {
    const { t } = props;

    return (
        <>
        <NavBar t = {props.t} />
        <ClientArea t = {props.t} />
        <Newsletter t = {props.t} />
        <Contactform t = {props.t} />
        <Footer2 t = {props.t} />
        </>
    )
}


export default withTranslation()(ClientAreaPage);
