import logo from './logo.svg';
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import React from 'react';
import {withTranslation} from 'react-i18next';
import "../node_modules/uikit/dist/css/uikit.min.css"

import NavBar from "./components/NavBar";
import FullVidBlock from "./components/FullVidBlock";
import MainServices from "./components/MainServices";
import CallToAction from "./components/CallToAction";
import WhatWeOffer from "./components/WhatWeOffer";
import WorldView from "./components/WorldView";
import Team from "./components/Team";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import ProjectsModal from "./components/ProjectsModal";
import i18n from './i18n';
import CountrySection from './components/CountrySection';
import Clipping from './components/Clipping';
import About from './components/About';
import Services from './components/Services';
import Pricing from './components/Pricing';
import Marketing from './components/Marketing';
import Newsletter from './components/Newsletter';
import Contactform from './components/Contactform';
import Footer2 from './components/Footer2';
import About2 from './components/About2';
import Apps from './components/Apps';
import HomePage from './Pages/HomePage';
import AppPage from './Pages/AppPage';
import AboutPage from './Pages/AboutPage';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import NewsPage from './Pages/NewsPage';
import ClientAreaPage from './Pages/ClientAreaPage';
import ScrollToTop from './components/ScrollToTop';

// loads the Icon plugin
UIkit.use(Icons);
// components can be called from the imported UIkit reference
//UIkit.notification("Hello world.");


function App(props) {
  return (
    <div>
      {/*<NavBar langChanged={i18n.language} />*/}
      {/* <FullVidBlock /> */}

      {/*
        <CallToAction id="agromap" t = {props.t} />
      */}
      
      {/*
      <Clipping />
      <WhatWeOffer id="services" t = {props.t} />
      <CountrySection t = {props.t}/>
      <WorldView />
      
      <Team id="team" t = {props.t}/>
      
      <Contact id="contactus" lang={i18n.language} t = {props.t} />
      <Footer t = {props.t}/>
      <ProjectsModal t = {props.t}/>
      */}
      <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <div style={{backgroundColor:"#F8F8F8"}}>
        <Route path='/' exact>
          <Redirect to = '/home' />
        </Route>

        <Route path='/home'>
          <HomePage t = {props.t} />
        </Route>

        <Route path='/news'>
          <NewsPage t = {props.t} />
        </Route>

        <Route path='/about'>
          <AboutPage t = {props.t} />
        </Route>

        <Route path='/apps'>
          <AppPage t = {props.t} />
        </Route>

        <Route path='/clientarea'>
          <ClientAreaPage t = {props.t} />
        </Route>
        </div>

      </Switch>
      </BrowserRouter>
      
    </div>
  );
}

export default withTranslation()(App);
