import logoWhite from "../img/logo-black.svg";
import feder from "../img/feder.png";
import React from 'react';
import { withTranslation } from 'react-i18next';

import projectA from "../img/projectoA.jpg";
import projectB from "../img/projectoB.jpg";
import projectC from "../img/projectoC.png";

const FooterBar = (props) => {
  const { t } = props;
    return (
      <div className="uk-margin-top uk-margin-bottom">
        <div
          uk-grid=""
          className="uk-child-width-auto@m uk-flex-middle uk-grid"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="uk-first-column">
            <div className="uk-text-left@m uk-text-center">
              <img uk-svg src={logoWhite} width="100" />
            </div>
          </div>
          <div className="uk-margin-auto">
            <ul uk-margin="" className="uk-subnav uk-flex-center">
              <li className="uk-first-column">
                <button
                  className="uk-button uk-button-default uk-margin-small-right"
                  type="button"
                  uk-toggle="target: #modal-close-default"
                  onClick={e => {
                    e.preventDefault();
                  }}
                >
                  <span id="Footer.projects">{t("FOOTER-PROJECTS")}</span>
                </button>
              </li>
            </ul>
          </div>

          <div className="uk-text-right@m uk-text-right">
            <div
              uk-grid=""
              className="uk-child-width-auto uk-grid-small uk-flex-center uk-grid"
            >
              <div>
                <a
                  href="https://www.linkedin.com/company/agroinsider"
                  uk-icon="icon: linkedin"
                  className="uk-icon-link uk-icon"
                />
              </div>
              <div>
                <a
                  href="https://twitter.com/agroinsider"
                  uk-icon="icon: twitter"
                  className="uk-icon-link uk-icon"
                />
              </div>
              <div>
                <a
                  href="https://www.facebook.com/Agroinsider/"
                  uk-icon="icon: facebook"
                  className="uk-icon-link uk-icon"
                />
              </div>
            </div>
          </div>
        </div>
        <img uk-img src={feder} style={{ maxWidth: "40%" }} />
      </div>
    );
}

const Footer = (props) => {
  const { t } = props;
    return (
      <div className="uk-section uk-section-secondary tm-section-secondary uk-section-large uk-padding-remove-bottom">
        <div className="uk-container uk-text-center">
          <h1 className="uk-h6 uk-margin-remove">
            <p id="Footer.meetUs">{t("FOOTER-MEETUS")}</p>
          </h1>
          <h2 className="uk-h1">
            <p id="Footer.ourOffices">{t("FOOTER-OUROFFICES")}</p>
          </h2>
          <p className="uk-text-large tm-text-large uk-margin-large-bottom">
            <p id="Footer.about">
              {t("FOOTER-ABOUT")}
            </p>
          </p>
          <div className="uk-child-width-expand@s" uk-grid>
            <div className="uk-grid-item-match">
              <h3 className="uk-h2 uk-margin-remove-bottom">
                <span className="uk-margin-small-right" uk-icon="location" />
                <p id="Footer.hq">{t("FOOTER-HQ")}</p>
              </h3>
              <p className="uk-margin-remove-top">
                Rua Circular Norte, Edifício NERE Sala 12.10 7005 - 841 Évora
              </p>
            </div>
            <div className="uk-grid-item-match">
              <h3 className="uk-h2 uk-margin-remove-bottom">
                <span className="uk-margin-small-right" uk-icon="location" />
                <p id="Footer.sub">{t("FOOTER-SUB")}</p>
              </h3>
              <p className="uk-margin-remove-top">
                Edificio World Trade Center Torre I, Piso 2 El Salvador
              </p>
            </div>
          </div>
          <hr className="uk-margin-remove-bottom uk-margin-xlarge-top" />
          <FooterBar t = {t}/>
        </div>
      </div>
    );
}

export default withTranslation()(Footer);
