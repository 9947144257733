import React from 'react';
import { withTranslation } from 'react-i18next';
import './Marketing.css'
import uvas from '../img/uvas.jpg'

const Marketing = (props) => {
    const { t } = props;

    return (
        <div className="marketing-container">
            <div className="marketing-container-title">
                <span>Ola</span>
            </div>
            <div className="marketing-container-subtitle">
                <span>Adeus</span>
            </div>
            <div className="marketing-cards-container">

                <div className="marketing-card">
                    <div className="marketing-card-image">
                        <img src={uvas} />
                    </div>
                    <div className="marketing-card-text">
                        <div className="marketing-card-text-title">
                            <span>20 May 2021</span>
                        </div>

                        <div className="marketing-card-text-subtitle">
                            <span>Lorem ipsum dolor sit Amet Consetetur</span>
                        </div>

                    </div>

                </div>

                <div className="marketing-card">
                    <div className="marketing-card-image">
                        <img src={uvas} />
                    </div>
                    <div className="marketing-card-text">
                        <div className="marketing-card-text-title">
                            <span>20 May 2021</span>
                        </div>

                        <div className="marketing-card-text-subtitle">
                            <span>Lorem ipsum dolor sit Amet Consetetur</span>
                        </div>
                    </div>

                </div>

                <div className="marketing-card">
                    <div className="marketing-card-image">
                        <img src={uvas} />
                    </div>
                    <div className="marketing-card-text">
                        <div className="marketing-card-text-title">
                            <span>20 May 2021</span>
                        </div>

                        <div className="marketing-card-text-subtitle">
                            <span>Lorem ipsum dolor sit Amet Consetetur</span>
                        </div>
                    </div>

                </div>

            </div>
            <div className="marketing-buttons">
                <button className="marketing-slideshow-button"></button>
                <button className="marketing-slideshow-button"></button>
                <button className="marketing-slideshow-button"></button>
            </div>

        </div>
    )

}


export default withTranslation()(Marketing);