import logo from "../img/logo-black.svg";
import React from 'react';
import { withTranslation } from 'react-i18next';
import './NavBar2.css'
import { NavLink } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown, Button } from 'react-bootstrap';
import Language from "./Language";

const NavBar2 = (props) => {
    const { t } = props;

    const scrollMe = (e, data) => {
        e.preventDefault();
        let t = document.getElementById(data);
        if (t) {

            window.scrollTo(0, t.offsetTop);
        }
        else {
            window.location = "/home";
            window.scrollTo(0, t.offsetTop);
        }
    };

    return (
        <div className="navbar-home">
            <Navbar style={{ margin: "auto", width: "95%" }} expand="lg" variant="dark">
                <Navbar.Brand href="#home"><img style={{ opacity: "0.8", width: "200px" }} className="navbar-img" src={logo} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav style={{ marginLeft: "auto" }}>
                        <div className="navbar-home-other-buttons">
                            <Nav.Link href="/home"><a style={{ color: "white", fontWeight: "bold" }}>{t("NAVBAR-HOME")}</a></Nav.Link>
                        </div>
                        <div className="navbar-home-other-buttons">
                            <Nav.Link href="/services" onClick={e => scrollMe(e, "services")}><a style={{ color: "white", fontWeight: "bold" }}>{t("NAVBAR-SERVICES")}</a></Nav.Link>
                        </div>
                        {/*<Nav.Link href="/news">{t("NAVBAR-NEWS")}</Nav.Link>*/}
                        <div className="navbar-home-other-buttons">
                            <Nav.Link href="/apps"><a style={{ color: "white", fontWeight: "bold" }}>{t("NAVBAR-APPS")}</a></Nav.Link>
                        </div>
                        <div className="navbar-home-other-buttons">
                            <Nav.Link href="//blog.agroinsider.com"><a style={{ color: "white", fontWeight: "bold" }}>{t("NAVBAR-BLOG")}</a></Nav.Link>
                        </div>
                        <div className="navbar-home-other-buttons">
                            <Nav.Link href="/clientarea"><a style={{ color: "white", fontWeight: "bold" }}>{t("CLIENT-AREA-TITLE")}</a></Nav.Link>
                        </div>
                        <div className="navbar-home-other-buttons">
                            <Nav.Link href="#contactus" onClick={e => scrollMe(e, "contactus")}><a style={{ color: "white", fontWeight: "bold" }}>{t("NAVBAR-CONTACTUS")}</a></Nav.Link>
                        </div>
                        <div className="navbar-home-other-buttons">
                            <Language />
                        </div>

                    </Nav>

                </Navbar.Collapse>
            </Navbar>


            {/*
            <div className="navbar-home-right-side">

            <NavLink className="inactive-home" activeClassName="active" to='/home'>{t("NAVBAR-HOME")}</NavLink>
                <NavLink className="inactive-home" activeClassName="active" to='/about'>{t("NAVBAR-ABOUT")}</NavLink>
                <NavLink className="inactive-home" href="#services"
                    uk-scroll
                    onClick={e => scrollMe(e, "services")} to='/home'>{t("NAVBAR-SERVICES")}</NavLink>
                <NavLink className="inactive-home" to='/news'>{t("NAVBAR-NEWS")}</NavLink>
                <NavLink className="inactive-home" to='/apps'>{t("NAVBAR-APPS")}</NavLink>
                <a className="inactive-home"
                    href="//blog.agroinsider.com"
                    target="_blank"
                >
                    <span id="NavBar.blog">{t("NAVBAR-BLOG")}</span>
                </a>
                <a className="contactus-home"
                    href="#contactus"
                    uk-scroll
                    onClick={e => scrollMe(e, "contactus")}
                >
                    <span id="NavBar.contact">{t("NAVBAR-CONTACTUS")}</span>
                </a>
                
                <ul>
                    <li>
                        <a
                            href="#home"
                            uk-scroll
                            onClick={e => scrollMe(e, "home")}
                        >
                            <span id="NavBar.home">{t("NAVBAR-HOME")}</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href="#aboutus"
                            uk-scroll
                            onClick={e => scrollMe(e, "aboutus")}
                        >
                            <span id="NavBar.aboutUs">{t("NAVBAR-ABOUTUS")}</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href="#services"
                            uk-scroll
                            onClick={e => scrollMe(e, "services")}
                        >
                            <span id="NavBar.services">{t("NAVBAR-SERVICES")}</span>
                        </a>
                    </li>
                    <li >
                        <a
                            href="#marketing"
                            uk-scroll
                            onClick={e => scrollMe(e, "marketing")}
                        >
                            <span id="NavBar.clipping">{t("NAVBAR-CLIPPING")}</span>
                        </a>
                    </li>
                    <li >
                        <a
                            href="#marketing"
                            uk-scroll
                            onClick={e => scrollMe(e, "marketing")}
                        >
                            <span id="NavBar.clipping">Apps</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href="//blog.agroinsider.com"
                            target="_blank"
                        >
                            <span id="NavBar.blog">Blog</span>
                        </a>
                    </li>
                    <li>
                        <a  style={{marginRight:"6em", backgroundColor:"#4CA65B", padding:"0.5em 1em"}}
                            href="#contactus"
                            uk-scroll
                            onClick={e => scrollMe(e, "contactus")}
                        >
                            <span id="NavBar.contact">{t("NAVBAR-CONTACT")}</span>
                        </a>
                    </li>
                </ul>
                */}
        </div>
    )
}


export default withTranslation()(NavBar2);
