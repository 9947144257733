import React from 'react';
import { withTranslation } from 'react-i18next';
import './Pricing.css'

const Pricing = (props) => {
    const { t } = props;

    return (
        <div className="pricing-container">
            <span className="pricing-title">Pricing & Plans</span>
            <span className="pricing-subtitle">Choose Your Best Plan</span>
            <div className="pricing-background">
                <div className="pricing-cards-container">

                    <div className="pricing-card">
                        <div className="pricing-card-text">
                            <span className="pricing-card-title">Basic Plan</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-price">ok</span>
                            <button className="pricing-button">CHOOSE PLAN</button>
                        </div>
                    </div>

                    <div className="pricing-card">
                        <div className="pricing-card-text">
                            <span className="pricing-card-title">Premium Plan</span>
                            <span className="pricing-card-subtitle">we are</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-price">ok</span>
                            <button className="pricing-button">CHOOSE PLAN</button>
                        </div>
                    </div>

                    <div className="pricing-card">
                        <div className="pricing-card-text">
                            <span className="pricing-card-title">Ultra Plan</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-subtitle">adeus</span>
                            <span className="pricing-card-price">ok</span>
                            <button className="pricing-button">CHOOSE PLAN</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}


export default withTranslation()(Pricing);