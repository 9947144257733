import React from 'react';
import { withTranslation } from 'react-i18next';
import './CardApps.css'

const CardApps = (props) => {

    const { t, img, title, titleAbove, subtitle  } = props;

    return (
        <div className="apps-card">
            <div className="apps-card-text">
                <span className="apps-card-title-top">{titleAbove}</span>
                <img src={img} style={{marginBottom:"2em", width:"150px"}} />
                <span className="apps-card-title">{title}</span>
                <span className="apps-card-subtitle">{subtitle}</span>
            </div>
        </div>
    )
}


export default withTranslation()(CardApps);
