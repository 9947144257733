import React from 'react';
import { withTranslation } from 'react-i18next';
import './ClientArea.css';
import Card from './Card';
import service from '../img/contact.png'
import agromap from '../img/area_cliente/agromap.png'
import smartag from '../img/area_cliente/smartag.png'

const ClientArea = (props) => {
    const { t } = props;


    return (

        <div className="client-area-container"> 
            <div className="client-area-container-cards">

                <div className="client-area-container-card">
                    <span className="client-area-container-card-title">Agromap</span>
                    <img src={agromap} style={{height:"33vw",width:""}} />
                    
                    <a href="https://agromap.agroinsider.com/" className="client-area-button" >{t("START")}</a>
                </div>

                <div className="client-area-container-card">
                    <span className="client-area-container-card-title">Smartag</span>
                    <img style={{height:"33vw"}} src={smartag} />
                    
                    <a className="client-area-button" href="https://smartag.agroinsider.com/" >{t("START")}</a>
                </div>

            </div>
        </div>

    )
}


export default withTranslation()(ClientArea);