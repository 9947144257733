import projectA from "../img/projectoA.jpg";
import projectB from "../img/projectoB.jpg";
import projectC from "../img/projectoC.png";
import projectD from "../img/Group.png"
import { withTranslation } from 'react-i18next';

import React from 'react';

const ProjectsModal = (props) => {
    const { t } = props;

    return (
        <div id="modal-close-default" className="uk-modal-container" uk-modal="true">
            <div className="uk-modal-dialog uk-modal-body">
                <button className="uk-modal-close-default" type="button" uk-close />
                <h2 className="uk-modal-title">
                    <span id="Projects.title">{t("PROJECTSMODAL-TITLE")}</span>
                </h2>
                <SlideShow t = {t} />
            </div>
        </div>
    );
}

const SlideShow = (props) => {
    const { t } = props;
    return (
        <div
            className="uk-position-relative uk-visible-toggle uk-dark"
            tabindex="-1"
            uk-slider="center: true"
        >
            <ul className="uk-slider-items uk-grid">

                <li className="uk-width-3-4">
                    <div
                        className="uk-panel"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <img src={projectD} alt="" />
                    </div>
                </li>

                <li className="uk-width-3-4">
                    <div
                        className="uk-panel"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <img src={projectB} alt="" />
                    </div>
                </li>
                <li className="uk-width-3-4">
                    <div
                        className="uk-panel"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <img src={projectC} alt="" />
                    </div>
                </li>
                <li className="uk-width-3-4">
                    <div
                        className="uk-panel"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <img src={projectA} alt="" />
                    </div>
                </li>

            </ul>

            <a
                className="uk-position-center-left uk-position-small uk-hidden-hover"
                href="#"
                uk-slidenav-previous
                uk-slider-item="previous"
            ></a>
            <a
                className="uk-position-center-right uk-position-small uk-hidden-hover"
                href="#"
                uk-slidenav-next
                uk-slider-item="next"
            ></a>
        </div>
    );
}


export default withTranslation()(ProjectsModal);
