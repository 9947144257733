import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  'en-GB': {
    translation: {
      "NAVBAR-HOME":"Home",
      "NAVBAR-ABOUT":"About us",
      "NAVBAR-SERVICES":"Services",
      "NAVBAR-NEWS":"News",
      "NAVBAR-APPS":"Apps",
      "NAVBAR-BLOG":"Blog",
      "NAVBAR-CONTACTUS":"Contact us",
      "FOLLOW":"Follow us: ",

      "ENTER-EMAIL":"Enter your email here",

      "NEWSLETTER":"Subscribe to our newsletter",
      "NEWSLETTER-SUBSCRIBE":"SUBSCRIBE",


      "ABOUTUS-TITLE":"About Us",
      "ABOUTUS-ENVIRONMENTAL-TITLE": "Environmental and agricultural sustainability",
      "ABOUTUS-ENVIRONMENTAL-TEXT": "Our mission is to promote environmental sustainability so that agriculture becomes more positive. A carbon neutral world generates positive agriculture.",
      "ABOUTUS-CARBON-TITLE": "Carbon Market Experts",
      "ABOUTUS-CARBON-TEXT": "A company specialized and dedicated to the carbon market. We develop carbon quantification projects on your farm.",

      "ABOUTUS-KNOW-MORE": "TO KNOW MORE",
      "ABOUTUS-INNOVATION-TITLE": "Innovation for your Business",
      "ABOUTUS-INNOVATION-TEXT": "We innovate your business with our most advanced technologies. We unite satellite technology with the convenience of a mobile application.",
      "ABOUTUS-DISCOVER-APPS": "DISCOVER THE APP’S",

      "SERVICES":"Services",
      "SERVICES-CARD1-TITLE": "Diagnostic",
      "SERVICES-CARD1-SUBTITLE": "Farm Diagnosis We analyze the activities performed on your farm, quantify them with the Agroinsider methodologies and issue a certificate with your environmental footprint, assigning amonetary value capable of being marketed.",
      
      "SERVICES-CARD2-TITLE": "Consulting",
      "SERVICES-CARD2-SUBTITLE": "We analyze and ensure the best quality of the entire cultivation process We indicate best practices for greater carbon sequestration on your farm.",

      "SERVICES-CARD3-TITLE": "Broker",
      "SERVICES-CARD3-SUBTITLE": "We help you buy and sell your carbon credits in the internationalmarket.",

      "CONTACTUS-TITLE": "Contact Us",
      "CONTACTUS-SUBTITLE": "Be part of the future with us",

      "CARBON-TITLE":"Carbon Solutions",

      "CARBON-CARD1-TITLE":"Smart farming",
      "CARBON-CARD1-SUBTITLE":"Greater agricultural efficiency leads to greater carbon efficiency",
      

      "CARBON-CARD2-TITLE":"Biodiversity",
      "CARBON-CARD2-SUBTITLE":"Plan to use uncultivated areas for carbon sequestration.",

      "CARBON-CARD3-TITLE":"Carbon credits",
      "CARBON-CARD3-SUBTITLE":"Quantification of carbon credits, validation, registration and commercialization.",

      "APPS-WEBAPPS":"Agroinsider Web Apps",
      "APPS-WEBAPPS-TEXT": "By combining agricultural management and data captured using satellite technologies, Agroinsider offers unique platforms for producers to promote efficient decisions.",
      "APPS-GET-STARTED":"GET STARTED",

      "APPS-FEATURES": "Features",
      "APPS-FEATURES-ICON1-TITLE": "Weekly reports",
      "APPS-FEATURES-ICON1-TEXT": "Weekly reports according to the status of your operation. Withcolor variations (green, yellow or red) according to the greatest variability.",
      
      "APPS-FEATURES-ICON2-TITLE": "Creation of management areas",
      "APPS-FEATURES-ICON2-TEXT": "Management maps according to the zones that present distinct variability in their plot. Ease at the time of introducinginputs in a sustainable way.",

      "APP-FEATURES-TITLE":"AgroMap and SmartAg alert system!",

      "APP-ALERTS": "Alerts",

      "APPS-FEATURES-CARD1-TITLE":"Alerts about the irrigation systems",
      "APPS-FEATURES-CARD1-SUBTITLE":"We optimize your water use and detect possible problems in the irrigation system.",

      "APPS-FEATURES-CARD2-TITLE":"Productivity deviation",
      "APPS-FEATURES-CARD2-SUBTITLE":"Real-time monitoring of your crop's vegetative development.",

      "APPS-FEATURES-CARD3-TITLE":"Alerts when anomalies are identified",
      "APPS-FEATURES-CARD3-SUBTITLE":"According to our algorithms, yellow or red alerts are generated according to the variability of the plot.",
      "PROJECTS":"Projects",
      "CLIENT-AREA-TITLE": "Client Area",
      "START":"ENTER",

    }
  },


  'es-ES': {
    translation: {
      "NAVBAR-HOME":"Inicio",
      "NAVBAR-ABOUT":"Acerca de nosotros",
      "NAVBAR-SERVICES":"Servicios",
      "NAVBAR-NEWS":"Noticias",
      "NAVBAR-APPS":"Apps",
      "NAVBAR-BLOG":"Blog",
      "NAVBAR-CONTACTUS":"Contacto",
      "FOLLOW":"Siga con nosotros: ",

      "ENTER-EMAIL":"Introduzca su correo electrónico aquí",

      "NEWSLETTER":"Suscríbase a nuestra newsletter",
      "NEWSLETTER-SUBSCRIBE":"SUSCRÍBASE", 


      "ABOUTUS-TITLE":"Acerca de nosotros",
      "ABOUTUS-ENVIRONMENTAL-TITLE": "Sostenibilidad Ambiental y Agrícola",
      "ABOUTUS-ENVIRONMENTAL-TEXT": "Nuestra misión es promover la sostenibilidad ambiental para que la agricultura sea más positiva. Un mundo neutro en carbono genera una agriculturapositiva.",
      "ABOUTUS-CARBON-TITLE": "Expertos en el Mercado de Carbono",
      "ABOUTUS-CARBON-TEXT": "Empresa especializada y dedicada al mercado del carbono. Desarrollamos proyectos de cuantificación de carbono en su explotación agraria.",

      "ABOUTUS-KNOW-MORE": "SABER MÁS",
      "ABOUTUS-INNOVATION-TITLE": "Innovación para su Negocio",
      "ABOUTUS-INNOVATION-TEXT": "Innovamos su negocio con nuestras tecnologías másavanzadas. Combinamos la tecnología de satélite con una práctica aplicación móvil.",
      "ABOUTUS-DISCOVER-APPS": "DESCUBRE LAS APP'S",

      "SERVICES":"Servicios",
      "SERVICES-CARD1-TITLE": "Auditoría",
      "SERVICES-CARD1-SUBTITLE": "Analizamos las actividades realizadas en su explotación, cuantificamos y emitimos un certificado con su marca ambiental, atribuyéndole un valor monetario a ser comercializado.",
      
      "SERVICES-CARD2-TITLE": "Consultoría",
      "SERVICES-CARD2-SUBTITLE": "Gestionamos y aseguramos la mejor calidad de todo el proceso productivo.Indicamos buenas prácticas para el mayor secuestro de carbono en su explotación.",

      "SERVICES-CARD3-TITLE": "Broker",
      "SERVICES-CARD3-SUBTITLE": "Asistimos en la compra y venta de sus créditos de carbono en el mercado internacional.",

      "CONTACTUS-TITLE": "Contacto",
      "CONTACTUS-SUBTITLE": "Se parte del futuro con nosotros",

      "CARBON-TITLE":"Soluciones  de Carbono",

      "CARBON-CARD1-TITLE":"Agricultura Inteligente",
      "CARBON-CARD1-SUBTITLE":"Mayor eficiencia agrícola genera una mayor eficiencia de carbono.",
      

      "CARBON-CARD2-TITLE":"Biodiversidad",
      "CARBON-CARD2-SUBTITLE":"Planificar el uso de áreas no cultivadas para el secuestro de carbono.",

      "CARBON-CARD3-TITLE":"Créditos CO₂",
      "CARBON-CARD3-SUBTITLE":"Cuantificación de créditos de carbono, validación, registro y comercialización.",

      "APPS-WEBAPPS":"Agroinsider Web Apps",
      "APPS-WEBAPPS-TEXT": "Con la combinación de gestión agrícola y datos capturados utilizando tecnologías satelitales, Agroinsider ofrece plataformas y aplicaciones únicas para los productores, de modo a promover decisiones eficientes.",
      "APPS-GET-STARTED":"EMPEZAR AGORA",

      "APPS-FEATURES": "Funciones",
      "APPS-FEATURES-ICON1-TITLE": "Reportes semanales",
      "APPS-FEATURES-ICON1-TEXT": "Reportes semanales según el estado de tu explotación. Con variaciones de color (verde, amarillo o rojo) según mayor variabilidad.",
      
      "APPS-FEATURES-ICON2-TITLE": "Creación de zonas de manejo",
      "APPS-FEATURES-ICON2-TEXT": "Mapas de gestión de acuerdo con las zonas que tienen diferentes características en su parcela. Facilidad para utilizar insumos de forma sostenible.",

      "APP-FEATURES-TITLE":"¡El sistema de alertas Agromap y Smartag!",

      "APP-ALERTS": "Alertas",

      "APPS-FEATURES-CARD1-TITLE":"Sistemas de riego",
      "APPS-FEATURES-CARD1-SUBTITLE":"Optimizamos su consumo de agua y detectamos posibles problemas en el sistema de riego.",

      "APPS-FEATURES-CARD2-TITLE":"Desviación de produtividad",
      "APPS-FEATURES-CARD2-SUBTITLE":"Monitoreo en tiempo real del desarrollo vegetativo de su cultivo",

      "APPS-FEATURES-CARD3-TITLE":"Anomalías",
      "APPS-FEATURES-CARD3-SUBTITLE":"Según nuestros algoritmos se generan alertas amarillas o rojas según la variabilidad de la parcela",
      "PROJECTS":"Proyectos",

      "CLIENT-AREA-TITLE": "Area de Cliente",
      "START":"INICIE",
    }
  },

  'pt-PT': {
    translation: {
      "NAVBAR-HOME":"Home",
      "NAVBAR-ABOUT":"Sobre nós",
      "NAVBAR-SERVICES":"Serviços",
      "NAVBAR-NEWS":"Novidades",
      "NAVBAR-APPS":"Apps",
      "NAVBAR-BLOG":"Blog",
      "NAVBAR-CONTACTUS":"Contacte nos",
      "FOLLOW":"Siga-nos: ",

      "ENTER-EMAIL":"Introduza aqui o seu email",

      "CLIENT-AREA-TITLE":"Area Cliente",

      "NEWSLETTER":"Subscreve a nossa newsletter",
      "NEWSLETTER-SUBSCRIBE":"SUBSCREVER",


      "ABOUTUS-TITLE":"Sobre nós",
      "ABOUTUS-ENVIRONMENTAL-TITLE": "Sustentabilidade Ambiental e Agrícola",
      "ABOUTUS-ENVIRONMENTAL-TEXT": "A nossa missão é promover a sustentabilidade ambiental de modo que a agricultura se torne mais positiva. Um mundo carbono neutro, gera uma agricultura positiva.",
      "ABOUTUS-CARBON-TITLE": "Especialistas no Mercado de Carbono",
      "ABOUTUS-CARBON-TEXT": "Empresa especializada e vocacionada para mercado do carbono.Desenvolvemos projetos de quantificação do carbono na sua exploração.",

      "ABOUTUS-KNOW-MORE": "SABER MAIS",
      "ABOUTUS-INNOVATION-TITLE": "Inovação para o seu Negócio",
      "ABOUTUS-INNOVATION-TEXT": "Inovamos o seu negócio com as nossas mais avançadas tecnologias. Unimos a tecnologia de satélite com a praticidade de uma aplicação móvel.",
      "ABOUTUS-DISCOVER-APPS": "DESCUBRA AS APP'S",

      "SERVICES":"Serviços",
      "SERVICES-CARD1-TITLE": "Auditoria",
      "SERVICES-CARD1-SUBTITLE": "Analisamos as atividades realizadas na sua exploração, quantificamos e emitimos um certificado com a sua pegada ambiental, atribuindo valor monetário a ser comercializado.",
      
      "SERVICES-CARD2-TITLE": "Consultoria",
      "SERVICES-CARD2-SUBTITLE": "Gerimos e asseguramos a melhor qualidade de todo o processo de cultivo. Aconselhamos as melhores práticas de gestão.",

      "SERVICES-CARD3-TITLE": "Broker",
      "SERVICES-CARD3-SUBTITLE": "Auxiliamos na compra e venda dos seus créditos de carbono no mercado internacional.",

      "CONTACTUS-TITLE": "Contacto",
      "CONTACTUS-SUBTITLE": "Faça parte do futuro connosco",

      "CARBON-TITLE":"Soluções de carbono",

      "CARBON-CARD1-TITLE":"Agricultura Inteligente",
      "CARBON-CARD1-SUBTITLE":"Maior eficiência agrícola gera maior eficiência de carbono.",
      

      "CARBON-CARD2-TITLE":"Biodiversidade",
      "CARBON-CARD2-SUBTITLE":"Planear o aproveitamento de áreas não cultivadas para o sequestro de carbono.",

      "CARBON-CARD3-TITLE":"Créditos CO₂",
      "CARBON-CARD3-SUBTITLE":"Quantificação dos créditos de carbono, validação, registo e comercialização.",

      "APPS-WEBAPPS":"Agroinsider Web Apps",
      "APPS-WEBAPPS-TEXT": "Através da combinação da gestão agrícola e dados capturada utilizando tecnologia satélite, a Agroinsider oferece plataformas e aplicações únicas para produtores, de modo a promover decisões eficientes.",
      "APPS-GET-STARTED":"SABER MAIS",

      "APPS-FEATURES": "Funções",
      "APPS-FEATURES-ICON1-TITLE": "Relatórios Semanais",
      "APPS-FEATURES-ICON1-TEXT": "Relatórios semanais de acordo com o estado da sua exploração. Com variações de cor (verde, amarelo ou vermelho) consoante a maior variabilidade.",
      
      "APPS-FEATURES-ICON2-TITLE": "Criação de áreas de gestão",
      "APPS-FEATURES-ICON2-TEXT": "Mapas de gestão de acordo com as zonas que apresentem variabilidades distintas na sua parcela. Facilidadeno momento de utilização de inputs de forma sustentável.",

      "APP-FEATURES-TITLE":"O sistema de alertas Agromap e Smartag!",

      "APP-ALERTS": "Alertas",

      "APPS-FEATURES-CARD1-TITLE":"Sistemas de rega",
      "APPS-FEATURES-CARD1-SUBTITLE":"Otimizamos o seu uso de água e detetamos possíveis problemas no sistema de rega.",

      "APPS-FEATURES-CARD2-TITLE":"Desvio de produtividade",
      "APPS-FEATURES-CARD2-SUBTITLE":"Acompanhamento em tempo real do desenvolvimento vegetativo da sua cultura.",

      "APPS-FEATURES-CARD3-TITLE":"Anomalias",
      "APPS-FEATURES-CARD3-SUBTITLE":"Segundo os nossos algoritmos são gerados alertas amarelos ou vermelhos de acordo com a variabilidade da parcela.",
      "PROJECTS":"Projects",

      "CLIENT-AREA-TITLE": "Área Cliente",
      "START": "COMEÇAR"
    }
  }
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'en-GB',
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });
export default i18n;