import appvid from "../img/app.mp4";
import agroconsulting from "../img/agroconsulting.png";
import vid from "../img/vid.mp4";
import React from 'react';
import { withTranslation } from 'react-i18next';
import NavBar from "./NavBar";
import "./MainServices.css"
import NavBar2 from "./NavBar2";

const MainServices = (props) => {
    const { t } = props;

    return (
        <div className="video-container">
            <video
                src={vid}
                type="video/mp4"
                loop
                muted
                playsinline
                uk-video="autoplay: inview"
            />
            <NavBar2 />
        </div>
    );
}

export default withTranslation()(MainServices);
