import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import './Footer2.css';
import logofooter from '../img/logonegativo.svg';
import bar from '../img/barra.png';
import { Modal, Button } from 'react-bootstrap';
import projectA from "../img/projectoA.jpg";
import projectB from "../img/projectoB.jpg";
import projectC from "../img/projectoC.png";
import projectD from "../img/Group.png";

import adress from '../img/icones_footer/adress.svg';
import facebook from '../img/icones_footer/facebook.svg';
import instagram from '../img/icones_footer/instagram.svg';
import linkedin from '../img/icones_footer/linkedin.svg';
import twitter from '../img/icones_footer/twitter.svg';

const Footer2 = (props) => {
    const { t } = props;
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("PROJECTS")}</Modal.Title>
                </Modal.Header>
                <Modal.Body><img src={projectA} /><img src={projectB} /><img src={projectC} /><img src={projectD} /></Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <div className="footer-container">
                <div className="upper-footer">
                    <img src={logofooter} style={{marginBottom:"2em"}} />
                    <div className="adress-container">
                        <div style={{display:"flex", alignItems:"start"}}>
                            <img src={adress} />
                        <div className="adress-container-left-side">
                            <div className="adress-container-left-side-text">
                                <span style={{marginLeft:"1em"}}>Avenida Vicente Salles Guimarães, 455 Bairro Alto Umuarama</span>
                                <span style={{marginLeft:"1em"}}>Uberlândia-MG - <span style={{fontWeight:"bold"}}>BRASIL</span></span>
                            </div>
                            
                        </div>
                        </div>

                        <div style={{display:"flex", alignItems:"start"}}>
                            <img src={adress} />
                            <div className="adress-container-right-side">
                                <span style={{marginLeft:"1em"}}>Europe - Headquarters Rua Circular Norte, Edifício NERE </span>
                                <span style={{marginLeft:"1em"}}>Sala 12.10 7005-841 Évora - <span style={{fontWeight:"bold"}}>PORTUGAL</span></span>
                            </div>
                        </div>
                        
                    </div>
                    <div className="adress-container-left-side-icons">
                                <span style={{marginLeft:"1em"}}>{t("FOLLOW")}</span>
                                <a className="link" href="https://www.facebook.com/Agroinsider/"> <img src={facebook} style={{marginRight:"1em"}} /> </a>
                                <a className="link" href="https://twitter.com/agroinsider?lang=en"> <img src={twitter} style={{marginRight:"1em"}}/></a>
                                <a className="link" href="https://www.linkedin.com/company/agroinsider"><img src={linkedin} style={{marginRight:"1em"}}/></a>
                                <a className="link" href="https://www.instagram.com/agroinsider_pt/"> <img src={instagram}/></a>
                            </div>
                </div>


                <div className="dowm-footer">
                    <hr className="hr-style" />
                    <div className="down-footer-container">
                        <span style={{ textDecoration: "underline" }}>Copyright@Agroinsider2021</span>
                        <img src={bar} style={{ width: "25vw" }} />
                        <button type="button" onClick={handleShow} className="projects-button">{t("PROJECTS")}</button>
                    </div>
                </div>

            </div>

        </>

    )
}


export default withTranslation()(Footer2);